import { Series, Page } from './schema';

export const getSeriesByLinkName = (
  allSeries: Array<Series>,
  linkName: string,
): Series | undefined => {
  return allSeries.find((series) => series.linkName === linkName);
};

export const getSeriesUrl = (series: Series): string => {
  return `/series/${series.linkName}`;
};

export const getPageBySlug = (
  allpages: Array<Page>,
  slug: string,
): Page | undefined => {
  return allpages.find((page) => page.slug === slug);
};
