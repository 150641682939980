import { SlideMenu, Style } from '../../components/SlideMenu/SlideMenu';
import MenuItem from '../../components/SlideMenu/MenuItem';

type MenuProps = {
  style: Style;
};

const Menu = ({ style }: MenuProps): JSX.Element => {
  return (
    <SlideMenu title={'Faith Vincent'} style={style}>
      <MenuItem path='/projects' text='Projects' />
      <MenuItem path='/bio' text='Bio' />
      <MenuItem path='/contact' text='Contact' />
    </SlideMenu>
  );
};

export default Menu;
