import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Series } from '../cms/schema';
import { getSeriesByLinkName } from '../cms/selectors';
import SeriesPage from '../pages/SeriesPage';
import TextPage from '../pages/TextPage';
import GalleryPage from '../pages/GalleryPage';

interface ProjectsRouteParams {
  linkName: string;
}

type ProjectsRouteProps = {
  allSeries: Array<Series>;
};

const SeriesRoute = ({ allSeries }: ProjectsRouteProps): JSX.Element => {
  const { linkName } = useParams<ProjectsRouteParams>();

  const series: Series | undefined = getSeriesByLinkName(allSeries, linkName);

  if (!series) {
    return (
      <TextPage key={'notfound'}>
        <h2>Not found</h2>
      </TextPage>
    );
  }

  return <SeriesPage series={series} />;
};

type ProjectsRouterProps = {
  allSeries: Array<Series>;
};

const ProjectsRouter = ({ allSeries }: ProjectsRouterProps): JSX.Element => {
  const match = useRouteMatch();

  if (match.isExact) {
    return <GalleryPage series={allSeries} />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/:linkName`}>
        <SeriesRoute allSeries={allSeries} />
      </Route>
    </Switch>
  );
};

export default ProjectsRouter;
