import { FunctionComponent } from 'react';
import ImageGallery from '../components/ImageGallery/ImageGallery';
import { Series } from '../cms/schema';
import Container from '../pages/fragments/Container';

type GalleryPageProps = {
  series: Array<Series>;
};

const GalleryPage: FunctionComponent<GalleryPageProps> = ({
  series,
}: GalleryPageProps) => {
  const allSeriesByYear: Array<Series> = series.sort((a, b) => {
    return b.year - a.year;
  });

  return (
    <Container>
      <ImageGallery title={'Projects'} series={allSeriesByYear} />
    </Container>
  );
};

export default GalleryPage;
