import { FunctionComponent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';
import Menu from './Menu';
import { COLORS } from '../../constants';

export enum Style {
  Light = 1,
  Dark,
}

type StyledMenuContainerProps = {
  open: boolean;
  style: Style;
};

const StyledMenuContainer = styled.div<StyledMenuContainerProps>`
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f6f5ef;

  a {
    text-decoration: none;
  }

  ${down('md')} {
    ${({ open }) => !open && 'height: 100px;'}
    ${({ style }) => style === Style.Light && 'height: auto;'}
  }
`;

type StyledMenuTextProps = {
  style: Style;
  open: boolean;
};

const StyledMenuText = styled.span<StyledMenuTextProps>`
  position: fixed;
  top: 20px;
  right: 20px;
  margin: auto;

  :hover {
    cursor: pointer;
  }

  z-index: 5;
  letter-spacing: 10px;
  padding-left: 18px;
  text-transform: lowercase;
  font-size: 18px;

  ${up('md')} {
    // TODO: Align menu text again if we got for this size
    font-size: 22px;
  }

  ${({ style, open }) =>
    style === Style.Dark && !open && 'color: ' + COLORS.dark + ';'}
  ${({ style, open }) =>
    (style === Style.Light || open) && 'color: ' + COLORS.light + ';'}

  :hover {
    cursor: pointer;
  }

  ${down('md')} {
    top: 0;
    right: 0;
    position: inherit;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 64px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #4a4444;
  }

  ${down('md', 'landscape')} {
    margin-top: 44px;
  }

  ${down('lg', 'landscape')} {
    color: #4a4444;
  }
`;

const StyledTitle = styled.h1`
  margin: auto;
  text-align: center;
  font-weight: 100;
  letter-spacing: 20px;
  z-index: 1;
  color: ${COLORS.dark};

  padding-top: 16px;

  ${down('md')} {
    padding-top: 16px;
    font-size: 16px;
    padding-left: 4%;
  }

  ${up('md')} {
    font-size: 26px;
  }
`;

type SlideMenuProps = {
  title: string;
  style: Style;
};

export const SlideMenu: FunctionComponent<SlideMenuProps> = ({
  title,
  style,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const node = useRef<HTMLDivElement>(null);
  const menuText = open ? 'Close' : 'Menu';
  const useBackgroundImage = style === Style.Dark;
  return (
    <StyledMenuContainer open={open} style={style} ref={node}>
      {/* Only show page title for the dark version */}
      {style === Style.Dark && !open && (
        <StyledTitle>
          <Link to={'/'}>{title}</Link>
        </StyledTitle>
      )}
      <StyledMenuText
        id='menu'
        open={open}
        onClick={() => setOpen(!open)}
        style={style}
      >
        {menuText}
      </StyledMenuText>
      <Menu useBackgroundImage={useBackgroundImage} open={open}>
        {children}
      </Menu>
    </StyledMenuContainer>
  );
};
