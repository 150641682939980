import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';

export type Image = {
  fallbackImageUrl: string;
  imageSizes: Array<ImageWithWidth>;
  description?: string;
};

export type ImageWithWidth = {
  url: string;
  width: number;
};

const StyledImageCaption = styled.div`
  display: block;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;

  // Font sizes for mobile vs. desktop
  ${down('md')} {
    font-size: 12px;
  }

  ${up('md')} {
    font-size: 13px;
  }

  p {
    line-height: 13px;
    padding-bottom: 0;
  }
`;

type ResponsiveImageProps = {
  image: Image;
  caption?: string;
  width: number;
};

const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = ({
  image,
  caption,
  width,
}: ResponsiveImageProps) => {
  const srcSet = image.imageSizes
    .map((imageSize) => `${imageSize.url} ${imageSize.width}w`)
    .join(',');

  return (
    <React.Fragment>
      <img
        width={`${width}%`}
        src={image.fallbackImageUrl}
        srcSet={srcSet}
        alt={image.description}
      />
      {caption && (
        <StyledImageCaption
          dangerouslySetInnerHTML={{ __html: caption }}
        ></StyledImageCaption>
      )}
    </React.Fragment>
  );
};

export default ResponsiveImage;
