import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { Style } from '../components/SlideMenu/SlideMenu';
import backgroundImage from './images/backgrounds/home.jpg';
import Menu from './fragments/Menu';
import { COLORS } from '../constants';

const HomeBackground = styled.div`
  background: no-repeat url(${backgroundImage});
  background-size: cover;
  height: 100vh;
  width: 100vw;

  ${down('md')} {
    background: no-repeat 55% 10% url(${backgroundImage});
    background-size: cover;
  }
`;

const HomeBackgroundTitle = styled.h1`
  top: 2vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  text-align: left;
  letter-spacing: 20px;
  font-weight: 300;
  color: ${COLORS.light};

  ${down('md')} {
    left: 4vw;
    font-size: 16px;
    text-align: center;
    color: #4a4444;
  }

  ${up('md')} {
    left: 2vw;
    font-size: 42px;
  }
`;

const Home: FunctionComponent = () => {
  return (
    <React.Fragment>
      <Menu style={Style.Light} />
      <HomeBackground>
        <HomeBackgroundTitle>Faith Vincent</HomeBackgroundTitle>
      </HomeBackground>
    </React.Fragment>
  );
};

export default Home;
