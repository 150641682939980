import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CONTENT } from './cms/content';
import { getPageBySlug } from './cms/selectors';
import ScrollToTop from './ScrollToTop';
import Home from './pages/Home';
import ExhibitionsPage from './pages/ExhibitionsPage';
import ContactPage from './pages/ContactPage';
import ProjectsRouter from './routers/ProjectsRouter';
import BioPage from './pages/BioPage';
import { FunctionComponent } from 'react';

const App: FunctionComponent = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path='/projects'>
          <ProjectsRouter allSeries={CONTENT.series} />
        </Route>
        <Route path='/exhibitions' exact={true}>
          <ExhibitionsPage page={getPageBySlug(CONTENT.pages, 'exhibitions')} />
        </Route>
        <Route path='/bio' exact={true}>
          <BioPage page={getPageBySlug(CONTENT.pages, 'bio')} />
        </Route>
        <Route path='/contact' exact={true}>
          <ContactPage page={getPageBySlug(CONTENT.pages, 'contact')} />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
