import styled from 'styled-components';
import { Page } from '../cms/schema';
import TextPage from './TextPage';
import InstagramLogo from './images/logo/instagram.svg';
import ResponsiveImage from '../components/ResponsiveImage/ResponsiveImage';
import { COLORS } from '../constants';

const StyledImageContainer = styled.div`
  width: 30%;
  margin-left: 35%;
  margin-top: 4em;
  margin-bottom: 3em;
`;

const StyledContactEmail = styled.p`
  text-align: center;
  text-transform: uppercase;
  a {
    text-decoration: none;
    color: ${COLORS.dark};
  }
`;

const StyledInstagramIcon = styled.span`
  text-align: center;
  display: block;
  img {
    width: 18px;
    height: 18px;
    filter: invert(60%) saturate(80%) hue-rotate(87deg) brightness(119%)
      contrast(80%);
  }
`;

type ContactPageProps = {
  page: Page | undefined;
};

const ContactPage = ({ page }: ContactPageProps): JSX.Element => {
  return (
    <TextPage key={'contact'}>
      <h2>{page?.name}</h2>
      {page?.image && (
        <StyledImageContainer>
          <ResponsiveImage width={100} image={page?.image} />
        </StyledImageContainer>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: page?.content ? page.content : '' }}
      ></div>
      <StyledContactEmail>
        <a href='mailto:contact@faithvincent.com'>Email</a>
      </StyledContactEmail>
      <br />
      <StyledInstagramIcon>
        <a
          href='https://www.instagram.com/faithvincent.art/'
          rel='noreferrer'
          target='_blank'
        >
          <img src={InstagramLogo} alt='Instagram' />
        </a>
      </StyledInstagramIcon>
    </TextPage>
  );
};

export default ContactPage;
