import { FunctionComponent } from 'react';
import styled from 'styled-components';
import MenuItems from './MenuItems';
import img from './menu.jpg';

interface StyledMenuProps {
  open: boolean;
}

const StyledMenuWithBackgroundImage = styled.nav<StyledMenuProps>`
  background: url(${img}) no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ open }) => (open ? '100vh' : '0')};
  z-index: 3;
  overflow: hidden;
`;

const StyledMenuWithOpacity = styled.nav<StyledMenuProps>`
  opacity: 0.6;
  background: #3e3b3b;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ open }) => (open ? '100vh' : '0')};
  z-index: 3;
  overflow: hidden;
`;

type MenuProps = {
  useBackgroundImage: boolean;
  open: boolean;
};

const Menu: FunctionComponent<MenuProps> = ({
  useBackgroundImage,
  open,
  children,
}) => {
  if (useBackgroundImage) {
    return (
      <StyledMenuWithBackgroundImage open={open}>
        <MenuItems open={open}>{children}</MenuItems>
      </StyledMenuWithBackgroundImage>
    );
  }
  return (
    <StyledMenuWithOpacity open={open}>
      <MenuItems open={open}>{children}</MenuItems>
    </StyledMenuWithOpacity>
  );
};

export default Menu;
