import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { up, down, between } from 'styled-breakpoints';

type StyleMenuItemsProps = {
  open: boolean;
};

const StyleMenuItems = styled.ul<StyleMenuItemsProps>`
  list-style: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;

  text-align: center;

  ${down('md', 'landscape')} {
    font-size: 11px;
  }

  ${between('md', 'lg')} {
    font-size: 20px;
    max-width: 45vw;
  }

  ${down('md', 'portrait')} {
    padding-inline-start: 0;
    padding-left: 5%;
    top: 23vh;
    max-width: 100vw;
  }

  ${up('lg')} {
    top: 15vh;
    max-width: 45vw;
    font-size: 50px;
  }

  li {
    padding-bottom: 50px;
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: 0.2s;
    transition-delay: ${({ open }) => (open ? '0.2s' : '0s')};
  }

  span {
    text-transform: lowercase;
    color: #fff;
    letter-spacing: 32px;
    display: block;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
`;

type MenuItemsProps = {
  open: boolean;
};

const MenuItems: FunctionComponent<MenuItemsProps> = ({ open, children }) => {
  return <StyleMenuItems open={open}>{children}</StyleMenuItems>;
};

export default MenuItems;
