import { Page } from '../cms/schema';
import TextPage from './TextPage';
import styled from 'styled-components';

const StyledExhibitionsLink = styled.p`
  text-align: center;
`;

type BioPageProps = {
  page: Page | undefined;
};

const BioPage = ({ page }: BioPageProps): JSX.Element => {
  return (
    <TextPage key={'bio'}>
      <h2>{page?.name}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: page?.content ? page.content : '' }}
      ></div>
      <StyledExhibitionsLink>
        <a href='/exhibitions'>Solo and group exhibitions</a>
      </StyledExhibitionsLink>
    </TextPage>
  );
};

export default BioPage;
