import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

const StyledFooterWrap = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0px;
`;

const StyledFooter = styled.div`
  margin: auto;
`;

const StyledFooterContent = styled.div`
  float: left;
  width: 100%;
  padding: 20px 0;

  p {
    float: left;
    width: 100%;
    text-align: center;

    ${down('md')} {
      font-size: 9px;
    }

    ${up('md')} {
      font-size: 12px;
    }
  }
`;

const Footer = (): JSX.Element => {
  return (
    <StyledFooterWrap>
      <StyledFooter>
        <StyledFooterContent>
          <p>
            &#169; Faith Vincent {new Date().getFullYear()}. All rights
            reserved.
          </p>
        </StyledFooterContent>
      </StyledFooter>
    </StyledFooterWrap>
  );
};

export default Footer;
