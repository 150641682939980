import { Page } from '../cms/schema';
import TextPage from './TextPage';
import styled from 'styled-components';

const StyleBody = styled.div`
  p {
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 0;
  }
`;

type ExhibitionsPageProps = {
  page: Page | undefined;
};

const ExhibitionsPage = ({ page }: ExhibitionsPageProps): JSX.Element => {
  return (
    <TextPage key={'writing'}>
      <h2>{page?.name}</h2>
      <StyleBody
        dangerouslySetInnerHTML={{ __html: page?.content ? page.content : '' }}
      ></StyleBody>
    </TextPage>
  );
};

export default ExhibitionsPage;
