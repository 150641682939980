import React, { FunctionComponent } from 'react';
import { up, down } from 'styled-breakpoints';
import styled from 'styled-components';
import Menu from './Menu';
import Footer from './Footer';
import { Style } from '../../components/SlideMenu/SlideMenu';

const StyledWrapper = styled.div`
  ${down('lg')} {
    margin-top: 15vh;
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
  }

  ${up('lg')} {
    margin-top: 7vh;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
`;

const Container: FunctionComponent = ({ children }) => {
  return (
    <React.Fragment>
      <Menu style={Style.Dark} />
      <StyledWrapper>{children}</StyledWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Container;
